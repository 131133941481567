<template>
  <b-card border-variant="primary">
    <validation-observer ref="simpleRules">
      <b-form
        @submit.prevent="
          () => {
            showModal('Are you sure want to submit?', 'save').then((value) => {
              if (value) submitForm();
            });
          }
        "
      >
        <b-row>
          <b-col md="4">
            <b-form-group label="Company Name" label-for="company">
              <validation-provider
                #default="{ errors }"
                name="Company"
                rules="required"
              >
                <b-form-textarea
                  id="company"
                  v-model="form.company"
                  placeholder="Company Name"
                  :disabled="inputDisabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Address" label-for="address">
              <validation-provider
                rules="required"
                #default="{ errors }"
                name="Address"
              >
                <b-form-textarea
                  id="address"
                  size="sm"
                  placeholder="Full Address"
                  v-model="form.address"
                  :disabled="inputDisabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label="City" label-for="city">
              <validation-provider
                rules="required"
                #default="{ errors }"
                name="city"
              >
                <b-form-input
                  id="city"
                  v-model="form.city"
                  placeholder="City"
                  :disabled="inputDisabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Zip Code" label-for="zip_code">
              <validation-provider name="Zip Code">
                <b-form-input
                  id="zip_code"
                  v-model="form.zip_code"
                  placeholder="Zip Code"
                  :disabled="inputDisabled"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="State" label-for="state">
              <validation-provider name="State">
                <b-form-input
                  id="state"
                  v-model="form.state"
                  placeholder="State"
                  :disabled="inputDisabled"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Country" label-for="country">
              <validation-provider
                rules="required"
                #default="{ errors }"
                name="Country"
              >
                <v-select
                  @input="getCountryFlag"
                  v-model="form.country"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="$store.state.master.country"
                  label="lbl_name"
                  value="lbl_id"
                  :reduce="(label) => label.lbl_id"
                  placeholder="Select"
                  :disabled="inputDisabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Website" label-for="website">
              <validation-provider #default="{ errors }" name="URL" rules="url">
                <b-form-input
                  id="website"
                  v-model="form.website"
                  placeholder="Website"
                  :disabled="inputDisabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <!--  -->
            <b-form-group label="Phone Number" label-for="phone">
              <validation-provider #default="{ errors }" name="Phone">
                <vue-tel-input
                  ref="telInput3"
                  :defaultCountry="defaultCountry"
                  v-model="form.phone"
                  @country-changed="handleCountryChanged($event, 'form.phone')"
                  :disabled="inputDisabled"
                  placeholder="Phone Number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Mobile Phone" label-for="mobile_phone">
              <validation-provider #default="{ errors }" name="Mobile Phone">
                <vue-tel-input
                  ref="telInput1"
                  :defaultCountry="defaultCountry"
                  @country-changed="
                    handleCountryChanged($event, 'form.mobile_phone')
                  "
                  v-model="form.mobile_phone"
                  :disabled="inputDisabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Fax" label-for="fax">
              <vue-tel-input
                ref="telInput2"
                :defaultCountry="defaultCountry"
                @country-changed="handleCountryChanged($event, 'form.fax')"
                v-model="form.fax"
                :disabled="inputDisabled"
                placeholder="Fax Number"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Language" label-for="language">
              <v-select
                v-model="form.language"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="$store.state.master.language"
                label="lbl_name"
                value="lbl_id"
                :reduce="(label) => label.lbl_id"
                placeholder="Select"
                :disabled="inputDisabled"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Contact Type" label-for="contact_type">
              <validation-provider #default="{ errors }" name="Contact Type">
                <v-select
                  v-model="form.contact_type"
                  :options="$store.state.master.contact_type"
                  label="lbl_name"
                  value="lbl_id"
                  :reduce="(label) => label.lbl_id"
                  placeholder="Select"
                  :disabled="inputDisabled"
                  multiple
                  :close-on-select="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Interest" label-for="interest">
              <v-select
                v-model="form.interest"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="$store.state.master.interest"
                :reduce="(label) => label.lbl_id"
                label="lbl_name"
                value="lbl_id"
                multiple
                placeholder="Select"
                :disabled="inputDisabled"
                :close-on-select="false"
              />
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Christmas Gift" label-for="christmas_card">
              <b-form-checkbox
                v-model="form.christmas_card"
                name="christmas_card"
                switch
                :disabled="inputDisabled"
              />
            </b-form-group>
          </b-col>

          <b-col sm="2">
            <b-form-group label="Event Invitation" label-for="christmas_gift">
              <b-form-checkbox
                v-model="form.christmas_gift"
                name="christmas_gift"
                switch
                :disabled="inputDisabled"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Bank Name" label-for="bank_name">
              <validation-provider #default="{ errors }" name="Bank Name">
                <b-form-input
                  id="bank_name"
                  v-model="form.bank_name"
                  placeholder="Bank Name"
                  :disabled="inputDisabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Swift Code" label-for="swift_code">
              <validation-provider #default="{ errors }" name="Swift Code">
                <b-form-input
                  id="swift_code"
                  v-model="form.swift_code"
                  placeholder="Swift Code"
                  :disabled="inputDisabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Account Number / IBAN"
              label-for="account_number"
            >
              <validation-provider #default="{ errors }" name="Account Number">
                <b-form-input
                  id="account_number"
                  placeholder="Account Number"
                  v-model="form.account_number"
                  :disabled="inputDisabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="VAT" label-for="vat">
              <b-form-input
                id="vat"
                v-model="form.vat"
                placeholder="VAT"
                :disabled="inputDisabled"
              />
            </b-form-group>
          </b-col>
          <b-col md="8">
            <b-form-group label="Note" label-for="note">
              <b-form-textarea
                id="note"
                size="sm"
                v-model="form.note"
                placeholder="Note"
                :disabled="inputDisabled"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-badge class="mb-1" variant="light-primary">
              Contact Person Details
            </b-badge>
            <feather-icon
              style="cursor: pointer"
              icon="PlusCircleIcon"
              size="25"
              @click="addContactPerson()"
            />
            <b-table
              small
              bordered
              striped
              size="sm"
              responsive
              :items="form.contactPersonArray"
              :fields="[
                { label: 'Name', key: 'name' },
                { label: 'Contact Type', key: 'position' },
                { label: 'Contact Number', key: 'contactNo' },
                { label: 'Email', key: 'email' },
                { label: 'Actions', key: 'actions' },
              ]"
            >
              <template #head()="scope"
                ><p style="text-transform: none">{{ scope.label }}</p></template
              >
              <template #cell(name)="data">
                <b-form-input
                  size="sm"
                  v-model="data.item.name"
                  id="invoice-data-sales-person"
                  placeholder="Name"
                  :disabled="inputDisabled"
                />
              </template>
              <template #cell(position)="data">
                <b-form-input
                  size="sm"
                  v-model="data.item.position"
                  id="invoice-data-sales-person"
                  placeholder="Contact Type"
                  :disabled="inputDisabled"
                />
              </template>
              <template #cell(contactNo)="data">
                <b-form-input
                  size="sm"
                  v-model="data.item.contactNo"
                  id="invoice-data-sales-person"
                  placeholder="Contact Number"
                  :disabled="inputDisabled"
                />
              </template>
              <template #cell(email)="data">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="email"
                >
                  <b-form-input
                    size="sm"
                    type="email"
                    v-model="data.item.emailId"
                    id="invoice-data-sales-person"
                    placeholder="Email "
                    :disabled="inputDisabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </template>
              <template #cell(actions)="data">
                <!-- v-if="
                    data.index != 0 &&
                    (!contact_id ||
                      contact_id == 'new' ||
                      !data.item.contactperson_id)
                  " -->
                <feather-icon
                  style="cursor: pointer"
                  icon="MinusCircleIcon"
                  size="25"
                  @click="
                    () => {
                      form.contactPersonArray.splice(data.index, 1);
                    }
                  "
                />

                <!-- <b-form-checkbox
                  @change="
                    () => {
                      data.item.status = !data.item.status;
                    }
                  "
                  v-if="
                    contact_id &&
                    contact_id != 'new' &&
                    data.item.contactperson_id
                  "
                  :checked="data.item.status"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  :disabled="inputDisabled"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox> -->

                <!-- <feather-icon
                  v-if="data.index == form.contactPersonArray.length - 1"
                  style="cursor: pointer"
                  icon="PlusCircleIcon"
                  size="25"
                  @click="addContactPerson()"
                /> -->
              </template>
            </b-table>
          </b-col>

          <!-- submit and reset -->
          <b-col md="12" class="d-flex justify-content-center mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BFormDatepicker,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BTable,
  BBadge,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import Treeselect from "@riophae/vue-treeselect";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import {
  addVendor,
  updateVendor,
  getVendorByID,
} from "@/apiServices/VendorServices";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url, length } from "@validations";
import { labels } from "@/utils/constants";
import _ from "lodash";
import { convertToIntArr } from "@/utils/helpers";
import showModal from "../../components/ModalConfirmation";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BFormTextarea,
    AppCollapse,
    AppCollapseItem,
    BFormDatepicker,
    vSelect,
    Treeselect,
    flatPickr,
    FeatherIcon,
    BFormSelect,
    BFormSelectOption,
    ToastificationContentVue,
    ValidationProvider,
    ValidationObserver,
    BTable,
    BBadge,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      contact_id: null,
      is_copy: false,
      form: {
        contactPersonArray: [],
        company: null,
        name: null,
        first_name: null,
        position: null,
        number: null,
        email: null,
        address: null,
        zip_code: null,
        city: null,
        state: null,
        country: null,
        website: null,
        phone: null,
        phone_data: null,
        mobile_phone: "",
        mobile_phone_data: null,
        fax: "",
        fax_data: null,
        language: null,
        contact_type: null,
        interest: [],
        christmas_card: null,
        christmas_gift: null,
        note: null,
        bank_name: null,
        swift_code: null,
        account_number: null,
        vat: null,
        required,
        length,
        email,
        url,
      },
      // country_list: [],
      // language_list: [],
      // contact_type_list: [],
      // interest_list: [],
      defaultCountry: "CH",
    };
  },
  computed: {
    inputDisabled() {
      return this.contact_id
        ? !this.$can("update", "contact")
        : !this.$can("create", "contact");
    },
    inputReadDisabled() {
      return this.contact_id
        ? this.$can("read", "contact")
        : this.$can("read", "contact");
    },
  },
  methods: {
    handleCountryChanged(object, str) {
      const val = _.get(this, str);
      if (!val || val.split(" ").length <= 1) {
        _.set(this, str, `+${object.dialCode}`);
      }
    },

    getCountryFlag(country_id) {
      if (country_id) {
        this.$store.state.master.country &&
          this.$store.state.master.country.length > 0 &&
          this.$store.state.master.country.map((country) => {
            if (country.lbl_id == country_id) {
              this.defaultCountry = country.lbl_value1;
              this.$refs.telInput1.choose(this.defaultCountry);
              this.$refs.telInput2.choose(this.defaultCountry);
              this.$refs.telInput3.choose(this.defaultCountry);
            }
          });
      } else {
        this.defaultCountry = "CH";
        this.$refs.telInput1.choose("CH");
        this.$refs.telInput2.choose("CH");
        this.$refs.telInput3.choose("CH");
      }
    },

    async getVendor() {
      const response = await getVendorByID(this.contact_id);
      if (response.data.data) {
        const data = response.data.data.vendorData;
        this.form = {
          company: data.company,
          name: data.name,
          first_name: data.fname,
          position: data.position,
          number: data.number,
          email: data.email,
          address: data.street,
          zip_code: data.zip_code,
          city: data.city,
          state: data.state,
          country: data.country ? parseInt(data.country) : null,
          website: data.website,
          phone: data.phone ? data.phone : "+41",
          account_number: data.account_number,
          mobile_phone: data.mobile ? data.mobile : "+41",
          christmas_card: data.c_card == 1 ? true : false,
          christmas_gift: data.c_gift == 1 ? true : false,
          fax: data.fax ? data.fax : "+41",
          language: data.language ? parseInt(data.language) : null,
          contact_type: data.contact_type,
          interest: convertToIntArr(data.interest),
          note: data.note,
          bank_name: data.bank_name,
          swift_code: data.ifsc,
          vat: data.vat,
          contactPersonArray: response.data.data.vendorContactPerson,
        };

        if (data.country) this.getCountryFlag(data.country);
      }
    },
    showModal,

    async submitForm() {
      this.$refs.simpleRules.validate().then(async (sucess) => {
        if (sucess) {
          try {
            if (this.is_copy) {
              this.contact_id = null;
            }

            let data = {
              vendor_id: this.contact_id,
              fname: this.form.first_name,
              name: this.form.name,
              company: this.form.company,
              position: this.form.position,
              street: this.form.address,
              number: this.form.number,
              zip_code: this.form.zip_code,
              city: this.form.city,
              country: this.form.country,
              email: this.form.email,
              website: this.form.website,
              phone: this.form.phone,
              mobile: this.form.mobile_phone,
              language: this.form.language,
              contact_type: this.form.contact_type,
              interest: this.form.interest,
              c_card: this.form.christmas_card,
              c_gift: this.form.christmas_gift,
              note: this.form.note,
              bank_name: this.form.bank_name,
              account_number: this.form.account_number,
              vat: this.form.vat,
              ifsc: this.form.swift_code,
              fax: this.form.fax,
              state: this.form.state,
              contactPersonArray: this.form.contactPersonArray,
            };

            let response = null;
            if (this.contact_id) {
              response = await updateVendor(this.contact_id, data);
            } else {
              response = await addVendor(data);
            }

            if (response.data.status) {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: response.data.message || "Success",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.go(-1);
            } else {
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: response.data.message || "Validation Failed",
                  icon: "EditIcon",
                  variant: "warning",
                },
              });
            }
          } catch (err) {
            console.error("Error in submitForm ", err);
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Error",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Please fill all mandatory feilds",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      });
    },

    resetForm() {
      this.form = {
        contactPersonArray: [],
        company: null,
        name: null,
        first_name: null,
        position: null,
        number: null,
        email: null,
        address: null,
        zip_code: null,
        city: null,
        state: null,
        country: null,
        website: null,
        phone: null,
        phone_data: null,
        mobile_phone: null,
        mobile_phone_data: null,
        fax: null,
        fax_data: null,
        language: null,
        contact_type: null,
        interest: [],
        christmas_card: null,
        christmas_gift: null,
        note: null,
        bank_name: null,
        swift_code: null,
        account_number: null,
        vat: null,
        email: null,
        url: null,
        defaultCountry: "CH",
      };
    },

    addContactPerson() {
      let obj = {
        name: "",
        position: "",
        contactNo: "",
        emailId: "",
        status: true,
        contactperson_id: null,
      };
      this.form.contactPersonArray.push(obj);
    },
  },

  beforeMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const is_copy = urlParams.get("copy");

    if (is_copy && is_copy == "1") {
      this.is_copy = true;
    }

    const contact_id = this.$route.params.contact_id;
    if (contact_id && !isNaN(contact_id)) {
      this.contact_id = contact_id;
      this.getVendor();
    } else {
      this.addContactPerson();
    }

    this.$store.dispatch("master/setLabelMaster", { key: labels.country });
    this.$store.dispatch("master/setLabelMaster", { key: labels.language });
    this.$store.dispatch("master/setLabelMaster", { key: labels.interest });
    this.$store.dispatch("master/setLabelMaster", { key: labels.contact_type });
  },
};
</script>

<style scoped lang="scss">
.form-rows {
  margin-bottom: 30px;
  padding: 5px;
  border: 1px solid;
  border-radius: 5px;
  border-color: gray;
}

.add-row {
  display: block;
  margin: auto;
  cursor: pointer;
}

.remove-row {
  display: block;
  margin: auto;
  cursor: pointer;
}

.collapse-group {
  margin-top: 30px;
  margin-bottom: 30px;
}
.country-selector__input {
  border-color: #1f304a !important;
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
